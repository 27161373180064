<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table
        class="uk-table uk-table-divider uk-table-middle uk-table-hover"
        aria-describedby="hulling-facility-table"
      >
        <header-hulling :table-header="listHeader" />
        <template v-if="listTableData">
          <tbody>
            <tr
              v-for="(content, index) in listTableData"
              :key="index"
            >
              <td>{{ content.pulping_code || '-' }}</td>
              <td>{{ content.pulping_name || '-' }}</td>
              <td>{{ content.pulping_partner_name || '-' }}</td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <empty-table
            :colspan="3"
          />
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import HeaderHulling from './header'
import EmptyTable from "@/components/globals/table/EmptyTable"

export default {
  components: {
    HeaderHulling,
    EmptyTable
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listTableData: {
      required: true,
      type: Array
    },
    listHeader: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.disabled {
  cursor: no-drop;
}
</style>
