<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-width-1-1 uk-flex uk-flex-middle">
      <div class="uk-margin-right">
        <img
          :src="`${images}/icon/ic_arrow_left_black.svg`"
          class="cursor-pointer"
          alt=""
          @click.prevent="$router.push({ name: 'HullingActivity'})"
        >
      </div>
      <div>
        <h1 class="uk-heading-line">
          {{ headerTitle }}
        </h1>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin-top-medium base-card">
      <activity-info 
        :id="id"
        :images="images"
        :loading-detail="loadingDetail"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from "@/utils/constant"
import getHeaderTitle from '@/utils/header-title'
import ActivityInfo from './Detail'

export default {
  components: {
    ActivityInfo
  },
  data() {
    return {
      id: this.$route.params.id,
      images: PREFIX_IMAGE,
      loadingDetail: true
    }
  },
  computed: {
    ...mapGetters({
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  async mounted() {
    this.loadingDetail
    await this.actionDetailHullingActivity({id:this.id})
    this.loadingDetail = false
  },
  methods: {
    ...mapActions({
      actionDetailHullingActivity: 'hullingActivity/getDetailHulling'
    }),
    ...mapMutations({
      setDataModalAddEdit: 'hullingActivity/SET_DATA_MODAL_ADD_EDIT',
      setToggleModalAddEditDiscard: 'hullingActivity/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
