<template>
  <div class="uk-form-stacked">
    <h4 class="uk-heading-line">
      Activity Info
    </h4>
    <div
      v-if="loadingDetail"
      class="uk-flex uk-flex-center uk-flex-middle"
    >
      <div uk-spinner />
    </div>
    <div
      v-else
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              VCH Code
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataActivityInfo.vch_code || '-'"
                name="vch_code"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Status
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataActivityInfo.status || '-'"
                name="Status"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Farmer Name
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataActivityInfo.partner_name || '-'"
                name="partner_name"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              <div class="uk-grid">
                <span class="uk-width-1-2 uk-text-left">Batch</span> 
                <span class="uk-width-1-2 uk-text-right">
                  <a 
                    class="action-details"
                    @click="modalHandler()"
                  >
                    Lihat Detail
                  </a>
                </span>
              </div>
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataActivityInfo.batch || '-'"
                name="area_manager"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <h5 class="uk-heading-line uk-margin-remove">
          Data Pulper
        </h5>
        <hr class="uk-margin-remove">
        <table-detail-activity
          :images="images"
          :list-table-data="dataDetailTable"
          :list-header="listHeader"
        />
      </div>

      <div class="uk-width-1-1">
        <h5 class="uk-heading-line uk-margin-remove">
          Data Produk
        </h5>
        <hr class="uk-margin-remove">
      </div>
        
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Species
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataProduct.species || '-'"
                name="vcp_code"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Berat Gabah Kering
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataProduct.weight_total + ' Kg' || '-'"
                name="Weight"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Varieties
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataProduct.varieties || '-'"
                name="Varieties"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Lapang Jemur
            </label>
            <div class="uk-form-controls">
              <input
                :value="dataProduct.drying_field_name || '-'"
                name="area_manager"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>

      <div class="uk-width-1-1">
        <h5 class="uk-heading-line uk-margin-remove">
          Data Pre-Hull Drying
        </h5>
        <hr class="uk-margin-remove">
      </div>

      <div class="uk-width-1-1">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-2">
            <label class="uk-form-label">
              Start Time
            </label>
            <div class="uk-form-controls">
              <input
                :value="startTime || '-'"
                name="Weight"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
          <div class="uk-inline uk-width-1-2">
            <label class="uk-form-label">
              End Time
            </label>
            <div class="uk-form-controls">
              <input
                :value="endTime || '-'"
                name="Weight"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
          <div class="uk-inline uk-width-1-2">
            <label class="uk-form-label">
              Durasi
            </label>
            <div class="uk-form-controls">
              <input
                :value="duration || '-'"
                name="Weight"
                class="uk-input readonly"
                type="text"
                autocomplete="off"
                readonly
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-plain 
      :modal-id="'modal-batch-hulling-activity'" 
      :modal-title="'Detail Batch ' + (modalItems[0]?.stage == 'Prehull' ? 'Pre-Hull Drying' : modalItems[0]?.stage) "
      :data-items="modalItems"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TableDetailActivity from './table/index'
import ModalPlain from '../detail/modal/ModalBatch'

export default {
  components: {
    TableDetailActivity,
    ModalPlain
  },
  props: {
    id: {
      required: true,
      type: String
    },
    images: {
      required: true,
      type: String
    },
    loadingDetail: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return{
      dataActivityInfo: {},
      dataProduct: {},
      detailBatch: [],
      dataDetailTable: [],
      startTime: '',
      endTime:'',
      duration:'',
      modalItems: [],
      listHeader: [ 
        {
          key: 'pulping_code',
          label: 'VCP Code',
          custom: 'sort'
        },
        {
          key: 'pulping_name',
          label: 'Facility Name',
          custom: 'sort'
        },
        {
          key: 'pulping_partner_name',
          label: 'Farmer Name',
          custom: 'sort'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      detailHullingActivity: 'hullingActivity/detail_hulling'
    })
  },
  watch: {
    detailHullingActivity(){
      if(this.detailHullingActivity){
        this.dataActivityInfo = this.detailHullingActivity.activity_info
        this.dataDetailTable = this.detailHullingActivity.pulping_facility_data
        this.dataProduct = this.detailHullingActivity.product_data
        this.batchData = this.detailHullingActivity.batch_data
        this.startTime = this.batchData.start_at
        this.endTime = this.batchData.end_at
        this.duration = this.batchData.end_at
        this.modalItems = this.detailHullingActivity.detail_batch
      }
    }
  },
  methods: {
    modalHandler(){
      window.UIkit.modal('#modal-batch-hulling-activity').show()
    }
  }
}
</script>
